@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;600&display=swap');

.fullPage {
    width: 980px;
    background-color: #E0FFFF;
    box-shadow: 6px 6px 20px rgb(0 0 0 / 10%);
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}

.fullPage__name {
    width: 100%;
    text-align: center;
    padding: 10px 0;
    background-color: #ADD8E6;
}

/* Enterprise page */
.flex-end {
    display: flex;
    align-items: flex-end;
}

.list-item {
    padding-top: 8px;
    border-bottom: 1px solid gray;
    width: 100%;
    /* text-align: center; */
}

.list-item__fieldName {
    min-width: 248px;
}

.list-item__fieldNamePers {
    min-width: 200px;
}

.list-item__fieldValue {
    width: 100%;
}

.list-item,
.data-table,
.block-header,
.rowFounderE {
    font-family: 'Montserrat', sans-serif;
    font-weight: 600;
}

.block-header {
    margin: 20px auto;
    font-size: 22px;
    color: #000080;
    text-align: center;
}

.rowFounderE {
    margin-bottom: 5px;
    border-bottom: 1px solid gray;
}

.rowFounderE__entr-name {
    display: flex;
    justify-content: space-between;
}

.data-table,
.sessions-table {
    width: 100%;
    border-collapse: collapse;
    border-bottom: 2px solid gray;
}

.data-table__header {
    background-color: #c5eaf6;
    color: #000080;
    border-bottom: 2px solid gray;
}

.data-table__header>th {
    padding: 8px 0;
}

.data-table__item {
    text-align: center;
}

.data-table__item-border {
    border-bottom: 1px solid gray;
}

.data-table__item>td {
    padding-top: 5px;
}

.data-table__person-name {
    min-width: 340px;
    text-align: left;
}

.data-table__person-state {
    width: 100%;
}

.data-table__btn-more {
    opacity: 0.2;
    transition: opacity .4s;
}

.data-table__switch {
    min-width: 32px;
    text-align: center;
    color: #000080;
    font-weight: bold;
    cursor: pointer;
    opacity: 0.2;
    transition: opacity .4s;
}

.data-table__item:hover .data-table__switch,
.data-table__item:hover .data-table__btn-more {
    opacity: 1;
}

.data-table__order-date {
    min-width: 100px;
}

.data-table__order-head {
    min-width: 360px;
}

.data-table__order-state {
    width: 100%;
}

.data-table__checkbox {
    display: none;
}

.data-table__more-info {
    border-bottom: 1px solid gray;
    background-color: #cfe8e8;
    font-size: 0;
    transition: font-size .7s;
}

.data-table__more-info div {
    padding-left: 15px;
}

.data-table__item:has(.data-table__checkbox:checked)+tr {
    font-size: 14px;
}

.data-table__lic-kateg {
    min-width: 60px;
}

.data-table__lic-number {
    min-width: 130px;
}

.data-table__lic-date {
    min-width: 110px;
}

.data-table__lic-state {
    width: 100%;
}

.data-table__obj-type {
    min-width: 280px;
}

.data-table__item .data-table__obj-type {
    text-align: left;
}

.data-table__obj-addr {
    width: 100%;
}

.data-table__obj-date {
    min-width: 100px;
}

.data-table__check-type {
    width: 100%;
    text-align: left;
}

.data-table__check-doc {
    min-width: 300px;
}

.data-table__checker {
    min-width: 172px;
    text-align: left;
}

.data-table__session-info {
    min-width: 180px;
}

/* Person page */
.fullPage__person-info {
    padding: 15px;
}

.fullPage__person-details {
    display: flex;
    justify-content: space-between;
    gap: 16px;
}

.fullPage__person-details > div {
    width: 100%;
}

.fullPage__person-foto,
.fullPage__person-foto__big,
.fullPage__person-foto__small {
    height: 200px;
    border: 1px solid black;
}

.fullPage__person-foto__small {
    display: none;
}

.person-header {
    margin: 35px 0 10px;
    text-align: center;
}

.person-foto__edit,
.person-posada__edit {
    display: flex;
    gap: 16px;
}

.person-foto__edit {
    justify-content: center;
    flex-wrap: wrap;
    margin-bottom: 24px;
}

/* Error page */
.error-page h1 {
    margin: 80px 20px;
    text-align: center;
}

.error-page h3 {
    margin: 0 30px;
    text-align: center;
}

.enterprs-list-wrap {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    overflow-y: scroll;
}

.enterprs-list-wrap__content {
    max-height: 400px;
    width: 100%;
}

.enterprs-list-wrap__item {
    font-size: 18px;
    margin-bottom: 4px;
}

.enterprs-list-wrap__item:hover {
    font-weight: 600;
}

.enterprs-list-wrap__item input {
    margin-right: 8px;
}

@media (max-width: 767px) {
    .person-posada__edit {
        flex-wrap: wrap;
    }

    .data-table__order-head,
    .data-table__lic-date,
    .data-table__obj-addr,
    .data-table__check-doc {
        display: none;
    }

    .data-table__obj-type {
        width: 100%;
        min-width: 0;
    }

    .fullPage__person-foto__big {
        display: none;
    }

    .fullPage__person-foto__small {
        display: block;
        margin: 0 auto 10px;
    }
}

@media (min-width: 768px) {

    .data-table__small-screen {
        display: none;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .fullPage {
        width: 760px;
    }

    .data-table__order-head {
        min-width: 280px;
    }

    .data-table__obj-type {
        min-width: 220px;
    }

    .data-table__check-doc {
        min-width: 240px;
    }

    .data-table__checker {
        min-width: 164px;
    }
}

@media (min-width: 481px) and (max-width: 767px) {
    .fullPage {
        width: 100%;
    }

    .list-item__fieldName {
        min-width: 220px;
    }

    .block-header {
        font-size: 20px;
    }

    .data-table__person-name {
        min-width: 240px;
    }

    .list-item,
    .data-table__header,
    .data-table__item {
        font-size: 14px;
    }

    .data-table__order-date {
        min-width: 80px;
    }

    .data-table__lic-kateg {
        min-width: 40px;
    }

    .data-table__lic-number {
        min-width: 120px;
    }

    .data-table__lic-state {
        margin-left: 14px;
    }

    .data-table__checkbox:checked+tr {
        font-size: 12px;
    }

    .data-table__checker {
        min-width: 140px;
    }

    .fullPage__person-foto__small {
        height: 300px;
    }
}

@media (max-width: 480px) {
    .fullPage {
        width: 100%;
    }

    .fullPage__name {
        font-size: 24px;
    }

    .rowFounderE {
        font-size: 14px;
    }

    .list-item__fieldName {
        min-width: 112px;
    }

    .block-header {
        font-size: 18px;
    }

    .data-table__person-name {
        min-width: 0;
        width: 60%;
    }

    .data-table__person-state {
        width: 40%;
    }

    .list-item,
    .data-table__header,
    .data-table__item {
        font-size: 12px;
    }

    .data-table__order-date {
        min-width: 70px;
    }

    .data-table__lic-kateg {
        min-width: 40px;
    }

    .data-table__lic-number {
        min-width: 80px;
    }

    .data-table__lic-state {
        margin-left: 10px;
    }

    .data-table__checkbox:checked+tr {
        font-size: 10px;
    }

    .data-table__checker {
        min-width: 120px;
    }
}